/* TermsAndConditions.module.css */

.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
  text-align: center;
}

.text {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 10px;
  }

  .text {
    font-size: 14px;
  }
}

/* Add more styles as needed for better responsiveness and aesthetics */
