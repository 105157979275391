.section3Container{
    padding: 2rem 0;
    }
    .section3Wrapper{
        display: flex;
        flex-direction: row;
        background:url('../assets/svg/rounded-div2.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        padding:2.5rem 3rem;
        height: 400px;
    }
    .section3Img{
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .section3Img img{
        /* height: 85%; */
        width: 100%;
    }
    .section3Details{
        display: flex;
        flex-direction: column;
        width: 70%;
        /* align-items: center; */
        justify-content: center;
        padding: 3rem;
    }
    .section3Heading{
        display: inline-block;
        border-bottom: 1px solid black;
        line-height: 50px;
        font-size: 30px;
        font-weight: 900;
    }
    
    .section3Details p{
        font-size: 16px;
        padding: 2rem 0;
        line-height: 25px;
    }
    
    .section3Person{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .section3Person img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .section3Person p{
        padding-left: 1rem;
        margin: 0;
    }
    @media(width<900px){
        .section3Container{
            padding: 1rem 0;
            }
            .section3Wrapper{
                display: flex;
                flex-direction: row;
                background:url('../assets/svg/rounded-div2.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                padding:1.5rem 2rem;
            height: 250px;
            }
            .section3Img{
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .section3Img img{
            height: 65%;

            }
            .section3Details{
                display: flex;
                flex-direction: column;
                width: 70%;
                /* align-items: center; */
                justify-content: center;
                padding: 1.3rem;
            }
            .section3Heading{
                display: inline-block;
                border-bottom: 1px solid black;
                line-height: 26px;
                 font-size: 20px;
            }
            
            .section3Details p{
                font-size: 14px;
            padding: 1rem 0;
            line-height: 20px;
            }
            
            .section3Person{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .section3Person img{
                width: 60px;
            height: 60px;
            border-radius: 50%;
            }
            .section3Person p{
                padding-left: 1rem;
            }
    }
    @media(width<700px){
        .section3Container{
            padding: 0.5rem 0;
            }
            .section3Wrapper{
                display: flex;
                flex-direction: row;
                background:url('../assets/svg/rounded-div2.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                padding:0.5rem 1rem;
            height: 250px;
            }
            .section3Details{
                display: flex;
                flex-direction: column;
                width: 70%;
                /* align-items: center; */
                justify-content: center;
                padding: 0.3rem;
            }
            .section3Heading{
                display: inline-block;
                border-bottom: 1px solid black;
                line-height: 20px;
                 font-size: 14px;
                 font-weight: 700;
            }
            
            .section3Details p{
                font-size: 12px;
            padding: 0.5rem 0;
            line-height: 14px;
            }
            .section3Img{
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 1rem;
            }
            .section3Img img{
            height: 55%;

            }
            .section3Person{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .section3Person img{
                width: 40px;
            height: 40px;
            border-radius: 50%;
            }
            .section3Person p{
                padding-left: 1rem;
            }
    }
    @media(width<500px){
        .section3Container{
            padding: 0.5rem 0;
            }
            .section3Wrapper{
                display: flex;
                flex-direction: row;
                background:url('../assets/svg/rounded-div2.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                padding:0.5rem 1rem;
            height: 200px;
            }
            .section3Details{
                display: flex;
                flex-direction: column;
                width: 70%;
                /* align-items: center; */
                justify-content: center;
                padding: 0.3rem;
            }
            .section3Heading{
                display: inline-block;
                border-bottom: 1px solid black;
                line-height: 13px;
                 font-size: 10px;
                 font-weight: 700;
            }
            
            .section3Details p{
                font-size: 9px;
                padding: 0.5rem 0;
                line-height: 10px;
                margin: 0;
            }
            .section3Img{
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 1rem;
            }
            .section3Img img{
            height: 45%;

            }
            .section3Person{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .section3Person img{
                width: 30px;
            height: 30px;
            border-radius: 50%;
            }
            .section3Person p{
                padding-left: 0.5rem;
            }
    }