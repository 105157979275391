/* VideoPlayer.css */
.videocontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #f0f0f0;
    margin-bottom: 10px;
    position: relative;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
      height: 0;
      overflow: hidden;
  }
  .videocontainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .videoelement {
    width: 100%;
    height: 100%;
    outline: none;
  }
  @media(max-width:500px){
    .videocontainer {
        height: 28vh;
      }
}