@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.colnav{
    background: #F55366;
    height: 40vh;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main{
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
  /* box-shadow: 1px 1px 5px black; */
}

.colnav h1{
  font-family: 'Poppins';
  color: white;
  font-weight: 700;
  text-align: center;
  /* padding-top: 15px; */
}

.inloc{
  width: 30vw;
  /* height: 45px; */
  background:  #FFFFFF;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* margin-top: 8vh; */
  border-right: 3px solid #D9D9D9;
}

.inloc input{
  width: 100%;
  height: 100%;
  border: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* padding-left: 5px; */
}
.inloc img{
  width: 6.5vw;
  height: 38px;
  padding: 3.5px;
  border-radius: 10px;
  border: 0px;
  cursor: pointer;
}


.shop{
  /* margin-top: 8vh; */
  padding: 0 1rem;
  background:#ffffff;
  /* font-weight: 600; */
  border-right: 3px solid #D9D9D9;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.shop2{
  /* margin-top: 8vh; */
  padding: 0 1rem;
  background:#ffffff;
  /* font-weight: 600; */
  border-right: 0px;
  display: flex;
  align-items: center;
  border-right: 3px solid #D9D9D9;
  cursor: pointer;
}
.shop3{
  /* margin-top: 8vh; */
  padding: 0.3rem 0.3rem;
  background:#ffffff;
  /* font-weight: 600; */
  border-right: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}



.drop{
  width: 20px;
  padding: 5px;
}
.spim{
padding: 5px;
}
.btn{
  padding: 1rem 10px;
  color: white;
  background: linear-gradient(180deg, #D93548 0%, rgba(255, 83, 105, 0.8) 100%);
  width: 100px;
  font-weight: 600;
  border-radius: 7px;
  height: 6.5vh;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.btn:hover{
  color: white;
  background: #d13046;
}

@media(width<900px){
  .main{
    margin: 0;
    width: 87%;
    /* padding: 3rem 0; */
  }
  .inloc{
    width: 220px;
    border: 0px;
    margin:  0 0 0.5rem 0;
  }
  .inloc input{
    padding: 0.7rem 0;
    font-size: 11.5px;
  }
  .shop{
    display: none;
  }
  .shop2{
    display: none;
  }
  
  .search .btn{
    border-radius: 7px;
    padding: 0.5rem 0.6rem;
    font-size: 11.5px;
    justify-content: center;
  }
  .shop3{
    /* margin-top: 8vh; */
    padding: 0rem 0rem;
    background:#ffffff;
    /* font-weight: 600; */
    border-right: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0px;
  }
}

.sb{
  padding: 2px;
  height: 25px;
}
@media(max-width:1100px){
  .inloc img{
    display: none;
  }
}
@media(max-width:500px){
.inloc{
    height: 4vh;
    border-radius: 2px;
  }
.colnav{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;   
    padding-top: 7px; 
}
.colnav h1{
    display: none;
  }
.shop{
    border:0px;
    border-radius: 2px;
    margin:  0 0 0.5rem 0;
    padding: 0.15rem 0.6rem;
    font-size: 11.5px;
    justify-content: center;
    margin: 0 7px 0 0;
    height: 5vh;
  }
.shop2{
    height: 5vh;
  }
.shop3{
    height: 4vh;
    
  }
  
.spim{
    padding: 2px;
    height: 20px;
    }
.drop{
      width: 10px;
      padding: 2px;
    }
.btn{
      /* width: 80px; */
      font-weight: 100;
      padding: 0rem 0.6rem;
    font-size: 11.5px;
    border-radius: 0px;
    height: 4vh;
    width: 70px;
    display: flex;
    justify-content: space-evenly;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: white;
    background: rgb(235, 11, 11);
      
    }
.sb{
      height: 18px;
    }

}