.maindiv{
    background: linear-gradient(180deg,white 30%, rgba(229, 63, 82, 0.2) 0%, rgba(255, 155, 38, 0.56) 92.5%);
    height: 45vh;
    margin-bottom: 10px;
    display: flex;
    overflow: hidden;
    font-family: 'Poppins';
    justify-content: space-evenly;
}

.div3{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.div31{
    display: flex;
    align-items: center;
    justify-content: center;
}

.div31 img{
    width: 150px;
}

.div32{
    display: flex;
    align-items: center;
    justify-content: center;
}

.div32 img{
    width: 150px;
}

@media(max-width:1050px){
    .div3 h1{
        font-size: 14px;
    }
    .div3 h4{
        font-size: 12px;
        text-align: center;
    }
    .div3 h5{
        font-size: 12px;
    }
   
    .maindiv{
        height: 25vh;
    }
    .div32{
        padding: 10px;
        text-align: center;
    }
    .div32 img{
        display: none;
    }
}



