@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.maindiv{
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.main1div{
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.smadiv1{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1571908598182-a3233d316b83?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZXRobmljJTIwd2VhcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;
}

.smadiv2{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1556906781-9a412961c28c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHNuZWFrZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv3{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1576775068800-4a9b572d5c57?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw2ODU0MjQ3M3x8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

    
}
.smadiv4{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1523779105320-d1cd346ff52b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aGFuZCUyMGJhZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;



}
.smadiv5{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1588713606197-023aea556d8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnwyMzM4NTM3MXx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;



}
.smadiv6{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1564859228273-274232fdb516?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjN8fHdlc3Rlcm4lMjB3ZWFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv11{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1612722432474-b971cdcea546?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Zm9ybWFsJTIwZHJlc3N8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;
}
.smadiv11M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1619472164610-9635b442ac5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

}
.smadiv12{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1574847872646-abff244bbd87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bGVoZW5nYXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv12M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1600574691453-499962cc0611?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv13{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1602712686580-3d2379c6224a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTB8fGNhc3VhbHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv13M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1548126032-079a0fb0099d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv14{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1541099649105-f69ad21f3246?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8amVhbnN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv15{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1603218183500-7e1d62c3c679?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHNsaXBwZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv16{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1617354839314-9476d4ca8a3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODR8fGNhc3VhbHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv16M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1533139502658-0198f920d8e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=742&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv17{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1602562887763-851fa56061e3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGphY2tldHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

}
.smadiv17M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1621607512022-6aecc4fed814?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

}
.smadiv18{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1572969565371-acc5b2b3496a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHNwb3J0JTIwc2hvZXN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv18M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1506638794690-bbea5c6f0ab7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv19{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1590508340390-ea4ba24fca36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGJlbHRzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv19M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1622020920816-cd528763211a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=408&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    margin-left: 0px;
    cursor: pointer;

}
.smadiv110{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1566206091558-7f218b696731?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDh8fGZhc2hpb258ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    cursor: pointer;

    background-size: cover;
    margin: 10px;
    margin-left: 0px;
}
.smadiv110M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1586057708056-6149710c46cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1886&q=80');
    background-position: center;
    cursor: pointer;

    background-size: cover;
    margin: 10px;
    margin-left: 0px;
}
.smadiv111{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1515347619252-60a4bf4fff4f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGZsYXRzJTIwYW5kJTIwaGVlbHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv111M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1602699120805-bff44dac7867?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=753&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv112{
    height: calc(40vh - 20px);
    background: url('https://plus.unsplash.com/premium_photo-1661661921619-f5230e6902f2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Zm9ybWFsc3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.smadiv112M{
    height: calc(40vh - 20px);
    background: url('https://images.unsplash.com/photo-1591258370467-f1ca1d70ea8f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80');
    background-position: center;
    background-size: cover;
    margin: 10px;
    cursor: pointer;

    margin-left: 0px;
}
.innerdiv{
    width: 100%;
    height: 100%;
    color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.innerdiv:hover{
    
    background: linear-gradient(0deg, rgba(63, 77, 126, 0.38), rgba(63, 77, 126, 0.38)),
linear-gradient(0deg, rgba(163, 163, 163, 0.26), rgba(163, 163, 163, 0.26));
backdrop-filter: blur(1.0px);
-webkit-backdrop-filter: blur(1.0px);
transition: 1;
font-family: 'Poppins';
color: white;
}
.innerdiv h5{
    position: relative;
    display: inline-block;
}

@media(max-width:1080px){
    .main1div{
        width: 100%;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
    }
    .smadiv14{
        margin: 10px;
    
    
    }
    .smadiv110{
        margin: 10px;
    
    
    }
}
@media(max-width:550px){
    .main1div{
        width: 100%;
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .smadiv12{
        margin: 10px;
    
    
    }
    .smadiv13{
        margin: 10px;
    
    
    }
    .smadiv14{
        margin: 10px;
    
    
    }
    .smadiv15{
        margin: 10px;
    
    
    }
    .smadiv16{
        margin: 10px;
    
    
    }
    .smadiv17{
        margin: 10px;
    
    
    }
    .smadiv18{
        margin: 10px;
    
    
    }
    .smadiv19{
        margin: 10px;
    
    
    }
    .smadiv110{
        margin: 10px;
    
    
    }
    .smadiv111{
        margin: 10px;
    
    
    }
    .smadiv112{
        margin: 10px;
    
    
    }
}
@media(max-width:980px){
    .maindiv{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .smadiv4{
        margin: 10px;
    
    
    }
}
@media(max-width:500px){
    .maindiv{
        grid-template-columns: 1fr 1fr;
    }
    .smadiv4{
        margin: 10px;
        margin-right: 0px;
    
    }
    .smadiv1{
        margin: 10px;
        margin-right: 0px;
    
    }
    .smadiv2{
        margin: 10px;
        margin-right: 0px;
    
    }
    .smadiv3{
        margin: 10px;
        margin-right: 0px;
    
    }
    .smadiv5{
        margin: 10px;
        margin-right: 0px;
    
    }
}
@media(max-width:500px){
    .maindiv{
        grid-template-columns: 1fr 1fr;
    }
    .smadiv4{
        margin: 10px;
    
    }
    .smadiv1{
        margin: 10px;
        height: 20vh;
        margin-right: 0;
        margin-bottom: 0;
    }
    .innerdiv{
        
        height: 100%;
        background: linear-gradient(0deg, rgba(63, 77, 126, 0.38), rgba(63, 77, 126, 0.38)),
linear-gradient(0deg, rgba(163, 163, 163, 0.26), rgba(163, 163, 163, 0.26));
backdrop-filter: blur(1.0px);
-webkit-backdrop-filter: blur(1.0px);
transition: 1;
color:whitesmoke;
font-family: 'Poppins';
    }
    .innerdiv h5{
       font-size: 16px;
    }
    .innerdiv h6{
        font-size: 14px;
     
    }
    .smadiv2{
        margin: 10px;
        height: 20vh;
        margin-bottom: 0;
    }
    .smadiv3{
        margin: 10px;
        height: 20vh;
        margin-right: 0;
        margin-bottom: 0;
    }
    .smadiv4{
        height: 20vh;
        margin-bottom: 0;
    }
    .smadiv5{
        margin: 10px;
        height: 20vh;
        margin-right: 0;
        
    }
    .smadiv6{
         height: 20vh;
         margin: 10px;       
    }
    .smadiv11{
        height: 20vh;
        margin-right: 5px;
        margin-bottom: 0px;

    }
    .smadiv12{
        height: 20vh;
        margin-left: 5px;
        margin-bottom: 0px;
        
    }
    .smadiv13{
        height: 20vh;
        margin-right: 5px;
        margin-bottom: 0px;
        
    }
    .smadiv14{
    
        height: 20vh;
        margin-left: 5px;
        margin-bottom: 0px;
        
    }
    .smadiv15{
    
        height: 20vh;
        margin-right: 5px;
        margin-bottom: 0px;

    }
    .smadiv16{
    
        height: 20vh;
        margin-left: 5px;
        margin-bottom: 0px;
    
    }
    .smadiv17{
    
        height: 20vh;
        margin-right: 5px;
        margin-bottom: 0px;
    }
    .smadiv18{
    
        height: 20vh;
        margin-left: 5px;
        margin-bottom: 0px;
    
    }
    .smadiv19{
        height: 20vh;
        margin-right: 5px;
        margin-bottom: 0px;

    }
    .smadiv110{
        height: 20vh;
        margin-left: 5px;
        margin-bottom: 0px;

    }
    .smadiv111{
        height: 20vh;
        margin-right: 5px;
    
    }
    .smadiv112{
        height: 20vh;
        margin-left: 5px;
    
    }
}