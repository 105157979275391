@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.colnav{
    background: white;
    height: 15vh;    
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: x-large;
    font-weight: 700;
    font-family: 'Poppins';
    padding-top:9vh;
}
.ttm{
    height: 45vh;
    background: linear-gradient(180deg, rgba(229, 63, 82, 0.2) 0%, rgba(255, 155, 38, 0.56) 92.5%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.tti{
    width: 200px;
    margin-left: 80px;
}
.ttm p{
    padding: 15%;
}
@media(max-width:700px){
    .ttm{
        flex-direction: column;    
        display: flex;
        height: 100vh;
        justify-content: center;
    }
    .tti{
        margin-left: 0px;
    }
  }

  @media(max-width:400px){
    .ttm{
        height: 70vh;
    }
    .tti{
        width: 180px;
        margin-left: 0px;
    }
  }