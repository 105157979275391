@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.colnav{
    background: white;
    height: 65vh;    
    display: flex;
    align-items: center;
    justify-content:space-between;
    font-family: 'Poppins';
    margin-bottom: 30px;
}
.tg{   
    display: flex;
}
.tg ul{   
    padding-right: 3%;
    list-style: none; 
}
.cg{
    height: 50vh;
    margin-left: 10vw;
}
.it{
    display: flex;
    flex-direction: column;
    margin-right: 5vw;
}
.it h3{
    padding: 3%;
}
.it h5{
    color: #2f2f2f;
    padding-left: 3%;
}
.it h4{
    color: #2f2f2f;
    padding-left: 3%;
}
.tg ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #E53F52; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 1.13em; /* Also needed for space (tweak if needed) */
  }
.tg1 li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #ffffff; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 1.13em; /* Also needed for space (tweak if needed) */
  }

  @media(max-width:700px){
    .cg{
        margin-top: 5vh;
    }
    .colnav{
        display: flex;
        height: 100vh;
    }
  }
  @media(max-width:1000px){
    .cg{
        margin-top: 5vh;
        width: 55%;
        height: 40vh;
        margin-left: 10px;
        margin-bottom: 0;
    }
    .colnav{
        flex-direction: column;    
        display: flex;
        height: 60vh;
    }
    .it h3{
        padding:3%;
        margin-top: 0;
        font-size: larger;
    }
    .it h5{
        color: #2f2f2f;
        padding-left: 3%;
    }
    li{
        font-size: small;
    }
    
  }