.pm{
    height: 60px;
    margin-bottom: 5px;
    margin-right: 100px;
    font-family: 'Poppins';
}
.navsty{
    background: #F9F9F9;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    align-items: center;
    padding-right: 20px;    
}
a{
    color: rgba(0, 0, 0, 0.751);
}
a:hover{
    color: black;
    text-decoration: none;
}
.m{
    height: 40px;
    margin-right: 5px;
}
.txt{
    display: flex;
    align-items: center;   
}
.innertxt{
    display: flex;
    flex-direction: column;
    font-size: x-small;
}

.txt p{
    display: flex;
    font-size: x-small;
    margin: 0 3px;
    font-weight: 600;
}
.txt h5{
    display: flex;
    font-size: small;
    font-weight: 700;
    margin: 3px;
}

@media(max-width:1300px){
    .m{
        height: 35px;
    }
    .pm{
        margin-right: 0px;
    }
}
@media(max-width:1020px){
    .m{
        height: 25px;
    }
    .pm{
        height: 45px;
        margin-right: 0px;
    }
    .txt p{
        display: none;
        margin: 0px;
    }
    .txt h5{
        display: flex;
        font-size: 12px;
        font-weight: 700;
        margin: 3px;
    }
}
@media(max-width:820px){
    .txt{
        display: flex;
        flex-direction: column;
    }
    .txt h5{
        font-size: 10px;
    }
}
@media(max-width:620px){
    .txt{
        display: flex;
        flex-direction: column;
    }
    .txt h5{
        font-size: 10px;
        font-weight: 500;
        letter-spacing: -0.03rem;
    }
}
@media(max-width:520px){
    .navsty{
        height: 8vh;
    }
    .innertxt{
        display: none;
    }
    .pm{
        height: 45px;
        margin-right: 35px;
        margin-bottom: 3px;
    }
    .m{
        height: 26px;
        margin-right: 0px;
        padding: 0px;
    }
}