.sidenav{
    background: linear-gradient(120.79deg, rgba(247, 186, 65, 0.93) 6.56%, rgba(245, 72, 92, 0.83) 94.06%);
    width: 35%;
    height: 100%;
}

.sidenav ul{
    display: flex;
    flex-direction: column;
    /* background-color: antiquewhite; */
    padding-left: 30%;
}

.sidenav ul li{
    list-style: none; 
    font-size: 20px;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem 2rem 1rem 2rem; 
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 20px 0 0 20px;
}
.sidenav ul li:hover{
    background-color: white;
}
.sidenav ul li.activeButton{
    background-color: white;
}
.inactiveButton{
    list-style: none; 
    font-size: 20px;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem 2rem 1rem 2rem;
    cursor: pointer;
    border-radius: 20px 0 0 20px;
}
.sidenav ul li p{
    font-size: 12px; 
    font-weight: 100;
}

.sidenav>p{
    text-align: center;
    padding: 2rem 0 0 0;
    font-weight: 600;
    font-size: 35px;
}

.sidenav>div{
    display: flex;
}

.sidenav>div div{
    display: flex;
    justify-content: center;
}
.sidenav>div div:first-child{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem 1rem 4rem;
}
.sidenav>div div:last-child{
    width: 50%;
}
.sidenav>div div:first-child img{
    width: 100%;
    padding: 1rem;
    border-radius: 50%;
}
.sidenav>div div:last-child{
   display: flex;
   flex-direction: column;
}
.sidenav>div div:first-child div{
    border: 2.3px solid rgb(50, 50, 50);
    border-style: dashed;
    border-radius: 20px;
    padding: 0.2rem 0.7rem;
    /* width: 150px; */
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #333;
}
.sidenav>div div:first-child div:hover{
    background-color: rgba(255, 255, 255, 0.45);
}


.sidenav>div div:first-child p{
    margin: 0.5rem 0;
    text-align: center;
    font-size: 13px;
    /* margin-bottom: 0.7rem; */
}
.sidenav>div div:last-child div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
}

.rightPart{
    width: 65%;
    /* background-color: aqua; */
    height: 100%;
}

.parent{
    display:flex;
    height: 900px;
}


/* ______________________________________ */

.personalDetails{
    display: flex;
    justify-content:center ;
    padding-top: 3rem;
}

.inloc{
    width: 45vw;
    /* height: 45px; */
    background:  #FFFFFF;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    border: 1px solid #D6D6D6;
box-shadow: 0px 14.6812px 17.8734px rgba(0, 0, 0, 0.11475), 0px 5.8px 6.6625px rgba(0, 0, 0, 0.085), 0px 1.26875px 2.37031px rgba(0, 0, 0, 0.05525);
border-radius: 13px;
  }
  
  .inloc input{
    width: 100%;
    height: 100%;
    border: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 1rem;
    margin-left: 0.5rem;
    padding: 0.5rem ;
    outline: none;
  }

.labelInloc{
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem;
}
.labelInloc2{
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem;
    display: flex;
}

.labelInloc2 input{
    margin-right: 0.5rem;
    zoom: 1.5;
}
.labelInloc2 label{
   margin: 0;
}

.butt{
    background: linear-gradient(120.79deg, rgba(247, 186, 65, 0.93) 6.56%, rgba(245, 72, 92, 0.83) 94.06%);
border: 1px solid rgba(251, 184, 50, 0.35);
-webkit-backdrop-filter: blur(7.4px);
backdrop-filter: blur(7.4px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 18px;
color: white;
width: 150px;
padding: 0.5rem;
}


@media(width<900px){
    .sidenav>div{
        flex-direction: column;
    }
    .sidenav>div div:first-child{
        width: 100%;
    }
    .sidenav>div div:last-child{
        width: 100%;
    }
    .parent{
        flex-direction: column;
    }
    .sidenav{
        width: 100%;
        height: fit-content;
    }
    .rightPart{
        width: 100%;
        height:fit-content;
    }
    .sidenav>div div:first-child{
        padding: 0 2rem;
    }
    .sidenav ul{
        padding-left: 0%;
        margin-top: 2rem;
    }
    .sidenav ul li{ 
        font-size: 15px;
        padding: 1.5rem ; 
        margin: 0;
        border-radius: 0;
        text-align: center;
    }
    .sidenav ul li p{
        margin: 0;
    }
    .inloc{
        width: 90vw;
    }
}



.customfileupload {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f2f2f2;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.customfileupload span {
  position: relative;
  z-index: 1;
}

.customfileupload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}