@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ttm{
    height: 45vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5%;
}
.story{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
}
.story p{
    text-align: center;
    font-size: small;
}

.pm{
    width: 80px;
    margin-bottom: 5px;
}
.disp{
    background: #FFEEC3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 5%;
    box-shadow: 0px 1.5481481552124023px 2.7703704833984375px 0px #7F5E000C;

box-shadow: 0px 7.31851863861084px 10.829629898071289px 0px #7F5E0013;

box-shadow: 0px 19px 34px 0px #7F5E001F;

}
.dis{
    background: #FFEEC3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 1%;
    box-shadow: 0px 1.5481481552124023px 2.7703704833984375px 0px #7F5E000C;

box-shadow: 0px 7.31851863861084px 10.829629898071289px 0px #7F5E0013;

box-shadow: 0px 19px 34px 0px #7F5E001F;

}
.dis h6{
    text-align: center;
}
.disp h6{
    text-align: center;
}
.dis h5{
    text-align: center;
    color: #FFEEC3;
}
.mm{
    width: 110px;
    margin-bottom: 8px;

}

@media(max-width:400px){
    .ttm{
        height: 25vh;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 5%;
    }
}