.colnav{
    background: #FFF3B2CC;
    display: flex;
    align-items: center;
    padding: 5px;
    height: 8vh;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
}
.yo{
    display: flex;
    flex-direction: row;
}
.drop{
    width: 9px;
    margin-left: 4px;
}

a{
    font-size: medium;
    display: inline;
}

@media(max-width:1200px){
    .drop{
        display: none;
    }
    a{
        font-size: small;
    }
}
@media(max-width:1100px){
    .drop{
        display: none;
    }
    a{
        font-size: small;
    }
}
@media(max-width:950px){
    .colnav{
        display: none;
    }
}

.parentUl{
    display: flex;
    justify-content: space-around;
}