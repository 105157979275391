@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Base styles for the footer container */
.FooterContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(
    180deg,
    #f55366 -0%,
    rgba(255, 155, 38, 0.56) 92.5%
  );
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: 'Poppins';
  padding: 2rem 0;
}

/* Wrapper styles */
.FooterWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;

}

/* Individual section styles */
.FooterWrapper > div {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


/* Media query for small screens (less than 1000px) */
@media (max-width: 1000px) {
  .FooterWrapper > div {
    width: 25%;
  }
}

/* Media query for even smaller screens (less than 400px) */
@media (max-width: 500px) {

  .FooterWrapper > div {
    width: 45%;
  }
  .FooterWrapper {
    gap: .1rem;
  }
  .FooterHeading {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .FooterHeading1 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
}

/* Footer heading styles */
.FooterHeading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.FooterHeading1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

/* List styles */
ul {
  list-style: none;
  padding: 0;
}

ul li a {
  color: #2f2f2f;
  font-size: 18px;
  text-decoration: none;
}

/* Social icons styles */
.SocialIcons {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  font-size: 1.5rem;
  margin-top: 0;
}

.SocialIcons li {
  margin: 0 0.5rem;
}

/* Contact info styles */
.ContactInfo {
  font-size: 0.9rem;
  text-align: center;
}
.ContactInfo a{
  padding: 10px;
}

.ContactInfo li {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

/* Hover effect on links */
ul li a:hover {
  color: black;
  text-decoration: underline;
}
